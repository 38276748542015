'use client'
import React, { Suspense, useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Image,
  Text,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useBreakpointValue,
  ModalFooter,
  Button,
  ModalHeader,
} from '@chakra-ui/react'

import { useUser } from '@/src/store/user-store'
import { useOnboarding } from '@/src/store/onboarding-store'
import { Interests } from '../auth-page/interests-onboarding'
import { useRouter } from 'next/navigation'
import { Languages } from '../auth-page/languages-onboarding'
import { cn } from '@/lib/utils'
import { toast } from 'sonner'
import HomefeedModalFooterGuest from './onhomefeed/homefeed-modal-footer-guest'
import { useLanguageInterestsMutation } from '@/src/mutations/auth/interest-language-mutation'
import HomefeedModalFooterUser from './onhomefeed/homefeed-modal-footer-user'

const SelectLangInterest = () => {
  const router = useRouter()
  const { user, _hasHydrated } = useUser()

  const {
    languageIds,
    interestIds,
    setLanguageIds,
    setInterestIds,
    setIsCompleted,
  } = useOnboarding()

  const languageIdsList = user
    ? user?.languageIds?.split(',')
    : languageIds
      ? languageIds.split(',')
      : []
  const interestIdsList = user
    ? user?.interestIds?.split(',')
    : interestIds
      ? interestIds.split(',')
      : []

  const [tabIndex, setTabIndex] = useState<number>(0)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const languageInterestsMutation = useLanguageInterestsMutation({})

  const shouldShowModal = !interestIdsList?.length || !languageIdsList?.length

  const position = useBreakpointValue({ base: 'flex-start', lg: 'center' })
  const spacing = useBreakpointValue({ base: '5', sm: '3' })
  const handleSignup = () => {
    router.push('/auth/signup')
  }

  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(
    languageIds?.length ? languageIds.split(',') : []
  )
  const [selectedInterests, setSelectedInterests] = useState<string[]>(
    interestIds?.length ? interestIds.split(',') : []
  )

  useEffect(() => {
    setSelectedLanguages(languageIds?.length ? languageIds.split(',') : [])
    setSelectedInterests(interestIds?.length ? interestIds.split(',') : [])
    if (interestIds?.length === 0) setTabIndex(0)
    else if (languageIds?.length === 0) setTabIndex(1)
  }, [interestIds, languageIds])

  const handleClose = () => {
    onClose()
    setSelectedLanguages(languageIds?.length ? languageIds.split(',') : [])
    setSelectedInterests(interestIds?.length ? interestIds.split(',') : [])
    if (interestIds?.length === 0) setTabIndex(0)
    else if (languageIds?.length === 0) setTabIndex(1)
  }

  useEffect(() => {
    if (user) {
      if (user.interestIds) {
        setSelectedInterests(user.interestIds.split(','))
      }
      if (user.languageIds) {
        setSelectedLanguages(user.languageIds.split(','))
      }
    }
  }, [user, setSelectedInterests, setSelectedLanguages])

  const handleNextStep = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    if (tabIndex === 0 && selectedInterests.length < 3) {
      toast.error('Please select at least three interests', { duration: 1500 })
      return
    }

    if (tabIndex === 1 && selectedLanguages.length < 3) {
      toast.error('Please select at least three languages', { duration: 1500 })
      return
    }

    if (user) {
      if (tabIndex === 0 && selectedInterests.length >= 3) {
        languageInterestsMutation.mutate(
          {
            interestIds: selectedInterests,
          },
          {
            onSuccess: () => {
              if (languageIdsList.length < 3) {
                setTabIndex(1)
              } else {
                onClose()
              }
            },
            onError: (error) => {
              console.error('Mutationfailed', error)
            },
          }
        )
        return
      }
      if (tabIndex === 1 && selectedLanguages.length >= 3) {
        languageInterestsMutation.mutate(
          {
            languageIds: selectedLanguages,
          },
          {
            onSuccess: () => {
              if (interestIdsList.length < 3) {
                setTabIndex(0)
              } else {
                onClose()
              }
            },
            onError: (error) => {
              console.error('Mutationfailed', error)
            },
          }
        )
        return
      }
    } else {
      if (
        tabIndex === 1 &&
        selectedLanguages.length >= 3 &&
        interestIdsList.length < 3
      ) {
        setLanguageIds(selectedLanguages.join(','))
        setIsCompleted(true)
        setTabIndex(0)
        return
      }

      if (
        tabIndex === 0 &&
        selectedInterests.length >= 3 &&
        languageIdsList.length < 3
      ) {
        setInterestIds(selectedInterests.join(','))
        setIsCompleted(true)
        setTabIndex(1)
        return
      }

      if (
        tabIndex === 0 &&
        selectedInterests.length >= 3 &&
        languageIdsList.length >= 3
      ) {
        setInterestIds(selectedInterests.join(','))
        setIsCompleted(true)
        onClose()
        return
      }

      if (
        tabIndex === 1 &&
        selectedLanguages.length >= 3 &&
        interestIdsList.length >= 3
      ) {
        setLanguageIds(selectedLanguages.join(','))
        setIsCompleted(true)
        onClose()
        return
      }
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: 'full', md: 'xl', lg: '2xl', xl: '3xl' }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius={'18px'} style={{ maxHeight: '90vh' }}>
          <ModalBody pb={0}>
            <Tabs
              variant="unstyled"
              index={tabIndex}
              onChange={(index) => setTabIndex(index)}
            >
              <TabList
                justifyContent={'center'}
                alignItems={'center'}
                my={4}
                position={'relative'}
              >
                <Tab
                  width="140px"
                  height="4px"
                  _focus={{ boxShadow: 'none', borderColor: 'none' }}
                  p={1}
                  bg={
                    // interestIdsList?.length > 0 ? 'lyk-green' :
                    '#D9D9D9'
                  }
                  rounded="full"
                  _selected={{ bg: 'lyk-green', w: '100px' }}
                  transition="background-color 0.6s ease"
                  mx={1}
                />
                <Tab
                  width="140px"
                  p={1}
                  height="4px"
                  _focus={{ boxShadow: 'none', borderColor: 'none' }}
                  bg={
                    // languageIdsList?.length > 0 ? 'lyk-green' :
                    '#D9D9D9'
                  }
                  rounded="full"
                  _selected={{ bg: 'lyk-green', w: '100px' }}
                  transition="background-color 0.6s ease"
                  mx={1}
                />
                <Image
                  src="/landing-page/contest/cross-small.svg"
                  onClick={handleClose}
                  alt="cross"
                  w={8}
                  h={8}
                  _hover={{ backgroundColor: '#D9D9D950' }}
                  className="absolute right-0 cursor-pointer rounded-lg"
                />
              </TabList>

              <TabPanels px={0}>
                <TabPanel py={0}>
                  <Box className="flex-1 pt-2 sm:border-b">
                    <Flex
                      textAlign={'center'}
                      flexDirection={'column'}
                      color={'#3E3E3E'}
                      mb={4}
                    >
                      <Text className="text-lg font-semibold">
                        Select your interests
                      </Text>
                      <Text className="text-sm opacity-50">
                        This helps us recommend more relevant content. Please
                        choose at least 3
                      </Text>
                    </Flex>
                    <Box className="h-[calc(100vh-346px)] overflow-y-scroll scrollbar-none sm:h-56 lg:h-72">
                      <Interests
                        key={interestIds}
                        selectedInterests={selectedInterests}
                        setSelectedInterests={setSelectedInterests}
                        position={position ?? 'center'}
                        spacing={spacing ?? '5'}
                      />
                    </Box>
                  </Box>
                </TabPanel>

                <TabPanel py={0}>
                  <Flex
                    textAlign={'center'}
                    flexDirection={'column'}
                    color={'#3E3E3E'}
                    mb={4}
                  >
                    <Text className="text-lg font-semibold">
                      Choose Preferred Language(s) for content
                    </Text>
                  </Flex>
                  <Box className="h-[calc(100vh-328px)] overflow-y-scroll scrollbar-none sm:h-56 lg:h-72">
                    <Languages
                      key={languageIds}
                      selectedLanguages={selectedLanguages}
                      setSelectedLanguages={setSelectedLanguages}
                      position={position ?? 'center'}
                    />
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter pt={0}>
            {user ? (
              <HomefeedModalFooterUser
                handleCancel={handleClose}
                handleChanges={handleNextStep}
              />
            ) : (
              <HomefeedModalFooterGuest
                handleSignup={handleSignup}
                handleNextStep={handleNextStep}
              />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {shouldShowModal && (
        <Box
          w="100%"
          className="absolute left-0 right-0 top-0 z-50 cursor-pointer"
        >
          <Flex
            className="relative items-center justify-center"
            onClick={onOpen}
          >
            <Image
              src={'/landing-page/hero2.png'}
              alt="Hero Image"
              className="h-24 w-full"
            />
            <Flex className="absolute flex-col items-center justify-center gap-1 px-4 text-center text-lg text-white sm:gap-2 sm:text-xl">
              <Suspense>
                <Flex className="flex-wrap items-center justify-center sm:flex-nowrap">
                  <Image
                    src={'/landing-page/whiteStar.svg'}
                    alt="Hero Image"
                    className="hidden h-2 w-2 sm:block sm:h-6 sm:w-8"
                  />
                  <Text
                    fontSize={{ base: '16px', sm: '20px', xl: '24px' }}
                    lineHeight={'normal'}
                    fontWeight={600}
                  >
                    Select language and interests for better recommendations
                  </Text>

                  <Flex className="items-center">
                    <Text className="block text-[14px] text-white sm:hidden">
                      Select
                    </Text>
                    <Image
                      src={'/landing-page/arrow-right-small.svg'}
                      alt="Hero Image"
                      className="h-6 w-6 items-center sm:h-10 sm:w-9"
                    />
                  </Flex>
                </Flex>
              </Suspense>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  )
}

export default SelectLangInterest
