import { useUser } from '@/src/store/user-store'
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/menu'
import { Avatar, Flex, Text } from '@chakra-ui/react'
import { ChevronDown } from 'lucide-react'
import Image from 'next/image'
import { Icons } from '../icons'
import Link from 'next/link'
import { useLogoutMutation } from '@/src/mutations/auth/logout-mutation'
import { useWalletBalanceQuery } from '@/src/queries/earnings/wallet-balance'
import { getInitials } from './avatar'

const getImageUrl = (url: string) => {
  if (url.startsWith('http')) {
    return url
  } else {
    return `https://cdn.lykstage.com/img/${url}`
  }
}

export const UserProfileBtn = () => {
  const { user, logout } = useUser()
  const logoutMutation = useLogoutMutation()
  const walletBalance = useWalletBalanceQuery()

  const handleDiwaliContestClear = () => {
    localStorage.removeItem('navratriContestLastClosedDate')
  }

  return (
    <Menu>
      <MenuButton>
        <Flex
          gap={{
            base: 1,
            md: 3,
          }}
          maxW={250}
          minW={{
            base: 'auto',
            md: 150,
          }}
          alignItems="center"
        >
          <Avatar
            getInitials={getInitials}
            w={10}
            h={10}
            bg={user?.themeColor || '#E79051'}
            color="white"
            name={user?.channelName}
            src={getImageUrl(user?.imageUrl || '')}
          />
          <Flex direction="column" hideBelow="md">
            <Text
              fontSize="medium"
              fontWeight="bold"
              noOfLines={1}
              textAlign="start"
            >
              {user?.firstName}
            </Text>
            <Flex gap={1} flex={1}>
              <Image
                src={'/icons/wallet.svg'}
                width={20}
                height={20}
                alt="wallet icon"
                priority
                loading="eager"
              />
              <Text
                fontSize="small"
                fontWeight="bold"
                textAlign="left"
                color="#24BFA3"
                noOfLines={1}
              >
                {walletBalance.data?.verified.lykCoin || '0'} LYK Coins
              </Text>
            </Flex>
          </Flex>
          <ChevronDown size={20} />
        </Flex>
      </MenuButton>
      <MenuList color="#3E3E3E" fontWeight={500} fontSize={14}>
        <MenuGroup>
          <MenuItem
            icon={<Icons.userCircle color="#787878" size={20} />}
            opacity={0.75}
            _hover={{ bg: '#ECECEC' }}
            px={5}
            py={2}
            as={Link}
            href={'/account'}
          >
            My Account
          </MenuItem>
          <MenuItem
            icon={<Icons.play color="#787878" size={20} />}
            opacity={0.75}
            _hover={{ bg: '#ECECEC' }}
            px={5}
            py={2}
            as={Link}
            href={'/c/' + user?.channelShareName}
          >
            My Channel
          </MenuItem>
          <Link href={'/earnings'}>
            <MenuItem
              icon={<Icons.wallet color="#787878" size={20} stroke="#787878" />}
              opacity={0.75}
              _hover={{ bg: '#ECECEC' }}
              px={5}
              py={2}
            >
              My Earnings
            </MenuItem>
          </Link>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup>
          {/* <MenuItem
            icon={<Icons.settings color="#787878" size={20} />}
            opacity={0.75}
            _hover={{ bg: '#ECECEC' }}
            px={5}
            py={2}
          >
            Settings
          </MenuItem>*/}
          <Link href={'/faqs'}>
            <MenuItem
              icon={<Icons.help />}
              opacity={0.75}
              _hover={{ bg: '#ECECEC' }}
              px={5}
              py={2}
            >
              Help & FeedBack
            </MenuItem>
          </Link>
          <Link href={'/home'}>
            <MenuItem
              icon={<Icons.logOut color="#787878" size={18} />}
              opacity={0.75}
              _hover={{ bg: '#ECECEC' }}
              px={5}
              py={2}
              onClick={() => {
                logout()
                logoutMutation.mutate()
                handleDiwaliContestClear()
              }}
            >
              Logout
            </MenuItem>
          </Link>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}
