import { Button, Flex } from '@chakra-ui/react'
import React from 'react'
import { buttonVariants } from '../button'
import { cn } from '@/lib/utils'

const HomefeedModalFooterUser = ({
  handleCancel,
  handleChanges,
}: {
  handleCancel: () => void
  handleChanges: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {
  return (
    <Flex
      justifyContent="space-between"
      flexDir={{ base: 'column-reverse', md: 'row' }}
      w={'full'}
      pt={{ base: '5px', sm: '14px' }}
      gap={{ base: 3 }}
      bg={'white'}
    >
      <Button
        type="submit"
        onClick={handleCancel}
        className={cn(
          buttonVariants({
            variant: 'destructive',
          }),
          'mx-2 mt-2 w-full px-6 tracking-wide lg:m-0 lg:mx-2 lg:w-32 '
        )}
      >
        Cancel
      </Button>

      <Button
        type="submit"
        bg={'#ED837F !important'}
        color={'white'}
        className={'w-full self-end px-6 tracking-wide lg:w-64'}
        onClick={handleChanges}
      >
        Apply
      </Button>
    </Flex>
  )
}

export default HomefeedModalFooterUser
