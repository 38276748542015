import { Button, Flex } from '@chakra-ui/react'
import React from 'react'
import { buttonVariants } from '../button'
import { cn } from '@/lib/utils'

const HomefeedModalFooterGuest = ({
  handleSignup,
  handleNextStep,
}: {
  handleSignup: () => void
  handleNextStep: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {
  return (
    <Flex
      justifyContent="space-between"
      flexDir={{ base: 'column-reverse', md: 'row' }}
      w={'full'}
      pt={{ base: '5px', sm: '14px' }}
      gap={{ base: 3 }}
      bg={'white'}
    >
      <Button
        type="submit"
        onClick={handleSignup}
        bg={'lyk-green'}
        color={'white'}
        className={cn(
          buttonVariants({
            variant: 'primaryGreen',
          }),
          'w-full self-end px-6 tracking-wide lg:w-32'
        )}
      >
        Signup
      </Button>

      <Button
        type="submit"
        bg={'#ED837F !important'}
        color={'white'}
        className={'w-full self-end px-6 tracking-wide lg:w-64'}
        onClick={handleNextStep}
      >
        Apply and continue as guest
      </Button>
    </Flex>
  )
}

export default HomefeedModalFooterGuest
