import { makeApiRequest } from '@/src/lib/api-request'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useReadNotificationMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['user', 'read-notification'],
    mutationFn: async (id: string) => {
      const response = await makeApiRequest({
        url: 'Notification/updateNotification',
        method: 'POST',
        data: { id },
        isAuthenticated: true,
        fallbackError: 'Failed to read notification',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications-count'],
      })
    },
  })
}
