import { useState, useEffect } from 'react'

export const getWindowDimensions = () => {
  return typeof window !== 'undefined'
    ? {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    : {
        width: null,
        height: null,
      }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowDimensions
}
