import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import { useToken } from '@/src/store/token-store'
import { useUser } from '@/src/store/user-store'
import { makeApiRequest } from '@/src/lib/api-request'

interface Notification {
  isread: string
  message: string
  notificationType: string
  createdAt: string
  _id: string
  imageUrl: string
  channelShareName: string
  encryptVideoUrl: string
  urlText: string
  url: string
}

interface RecentNotificationResponse {
  notifications: Notification[]
  offset: number
}

export const useNotifications = () => {
  const { accessToken } = useToken()
  const { user } = useUser()
  return useInfiniteQuery({
    initialPageParam: { offset: 0 },
    queryKey: ['notifications', user],
    queryFn: async ({ pageParam }): Promise<RecentNotificationResponse> => {
      const newOffset = pageParam.offset
      const dataProps = {
        limit: 4,
        offset: newOffset,
      }
      const response = await makeApiRequest({
        url: 'Notification/getNotification',
        method: 'POST',
        fallbackError: 'Failed to get notifications',
        isAuthenticated: true,
        data: dataProps,
      })
      const notifications: Notification[] = response.response
      return { notifications: notifications, offset: newOffset }
    },
    getNextPageParam: (lastPage: RecentNotificationResponse) => {
      if (lastPage.notifications.length === 0) return undefined
      const newOffset = lastPage.offset + 4
      return { offset: newOffset }
    },
    enabled: !!(user && accessToken),
    refetchOnWindowFocus: false,
  })
}

export const useNotificationCount = () => {
  const { accessToken } = useToken()
  const { user } = useUser()

  return useQuery({
    queryKey: ['notifications-count', user],
    queryFn: async () => {
      const response = await makeApiRequest({
        url: 'Notification/countNotification',
        method: 'GET',
        fallbackError: 'Failed to get notification count',
        isAuthenticated: true,
        data: {},
      })

      return response.response
    },
    enabled: !!(user && accessToken),
    refetchOnWindowFocus: false,
  })
}
