'use client'

import { useState, useEffect, useCallback, useRef } from 'react'
import { Icons } from '../icons'
import { Input } from '../ui/input'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import {
  Box,
  Button,
  Flex,
  Show,
  Image,
  Icon,
  useDisclosure,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react'
import { useDebounce } from '@uidotdev/usehooks'
import { RxCross1 } from 'react-icons/rx'
import Link from 'next/link'
import { useUser } from '@/src/store/user-store'
import { UserProfileBtn } from './user-profile-btn'
import { NAVBAR_HEIGHT_PX } from '@/lib/constans'
import { useSidebar } from '@/src/store/sidebar-store'
import { useSearch } from '@/src/store/search-store'
import { useSearchOnTypeQuery, useSearchQuery } from '@/src/queries/home/search'
import { Notifications } from './notifications'
import { useNotificationCount } from '@/src/queries/home/notifications'
import VideoUploadFlow from '../upload/videouploadflow'
import { excludedPages } from './sidebar'
import SelectLangInterest from './select-lang-interest'
import { useOnboarding } from '@/src/store/onboarding-store'

interface SearchOnTypeDto {
  description: string
  country: string
  city: string
  ip: string
}

export const Search = ({
  handleClosePopup,
  ui,
}: {
  handleClosePopup?: boolean
  ui?: boolean
}) => {
  const router = useRouter()
  const { user } = useUser()
  const pathname = usePathname()
  const { history, setHistory } = useSearch()
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const sp = useSearchParams()
  const debouncedQuery = useDebounce(searchVal, 200)
  const searchRef = useRef<HTMLInputElement>(null)
  const { data: searchText } = useSearchOnTypeQuery(debouncedQuery)

  const { data: searchRecommendations } = useSearchQuery()
  useEffect(() => {
    if (handleClosePopup) {
      searchRef?.current?.blur()
    }
  }, [handleClosePopup])

  useEffect(() => {
    setSearchVal(
      sp
        ?.get('q')
        ?.replace(/^["'](.+(?=["']$))["']$/, '$1')
        .replaceAll('"', '') || ''
    )
  }, [sp])

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(sp?.toString())
      params.set(name, value)
      return params.toString()
    },
    [sp]
  )

  const handleSearchSubmit = () => {
    if (searchVal.length == 0) {
      return
    } else {
      setIsPopupVisible(false)
      if (!user) {
        setHistory(searchVal)
      }
      router.push('/search/?' + createQueryString('q', searchVal))
    }
  }

  const handleSuggestionSubmit = (suggestion: string) => {
    setIsPopupVisible(false)
    setSearchVal(suggestion)
    router.push('/search/?' + createQueryString('q', suggestion))
  }

  const [searchData, setSearchData] = useState<string[]>([])
  const [trendingData, setTrendingData] = useState<string[]>([])

  const showPopup = () => {
    setIsPopupVisible(true)
  }

  const hidePopup = () => {
    setIsPopupVisible(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value)
  }

  useEffect(() => {
    if (searchVal) {
      setSearchData(searchText?.map((st) => st.description) || [])
      setTrendingData([])
    } else {
      if (!user) {
        const newData =
          searchRecommendations
            ?.filter((fil) => fil.sreachType === 'history')
            ?.map((st) => st.description) || []
        setSearchData(newData)
        setTrendingData(
          searchRecommendations
            ?.filter((fil) => fil.sreachType === 'popular')
            ?.map((st) => st.description) || []
        )
      } else {
        setSearchData(
          searchRecommendations
            ?.filter((fil) => fil.sreachType === 'history')
            ?.map((st) => st.description) || []
        )
        setTrendingData(
          searchRecommendations
            ?.filter((fil) => fil.sreachType === 'popular')
            ?.map((st) => st.description) || []
        )
      }
    }
  }, [searchVal, searchText, searchRecommendations])

  const [scrollUpDown, setScrollUpDown] = useState(0)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScrollSearch = () => {
        setScrollUpDown(window?.scrollY || 0)
      }
      handleScrollSearch()
      window.addEventListener('scroll', handleScrollSearch)
      return () => {
        window.removeEventListener('scroll', handleScrollSearch)
      }
    }
  }, [])

  const searchBoxCss = !ui
    ? 'border-[#d3d3d3] bg-white focus-within:border-[#d3d3d3] active:border-[#d3d3d3]'
    : 'border-white bg-[#78928d] focus-within:border-white active:border-white'

  const searchInputCss = !ui
    ? 'placeholder:text-slate-500 bg-white focus:border-[#d3d3d3] active:border-[#d3d3d3] focus:ring-0 focus-visible:ring-0 focus-visible:outline-none'
    : 'text-white placeholder:text-white bg-[#78928d] focus:border-white active:border-white focus:ring-0 focus-visible:ring-0 focus-visible:outline-none'

  const searchIconCss = !ui ? 'text-black/80' : 'text-white'

  return (
    <div className="searchCont mx-8 w-full max-w-[650px] flex-1">
      <div className="flex w-full flex-1 items-center gap-3">
        <div className="relative flex flex-1">
          <div
            className={`flex w-full items-center overflow-hidden rounded-full border-2 px-4 opacity-75 focus-within:border-[#2977f5] ${searchBoxCss}`}
          >
            <Input
              type="text"
              ref={searchRef}
              className={`focus-visible:out z-0 h-10 flex-1 border-0 pl-0 pr-2 placeholder:text-[14px] placeholder:font-normal focus:border-0 focus:outline-none focus-visible:shadow-none focus-visible:outline-none focus-visible:ring-0 ${searchInputCss}`}
              placeholder="Search"
              onFocus={showPopup}
              onBlur={hidePopup}
              defaultValue={sp?.get('q') || ''}
              value={searchVal}
              autoComplete="false"
              onChange={handleChange}
              onKeyDown={(event) => {
                if (event.key == 'Enter') {
                  handleSearchSubmit()
                }
              }}
            />
            {searchVal.length > 0 ? (
              <Icon
                as={RxCross1}
                color={!ui ? 'red' : 'white'}
                className="h-5 w-5 cursor-pointer lg:h-5 lg:w-5"
                onClick={() => setSearchVal('')}
              />
            ) : (
              <Icons.Search
                className={`h-5 w-5 cursor-pointer lg:h-6 lg:w-6 ${searchIconCss}`}
              />
            )}
          </div>
          {isPopupVisible &&
            ((searchData && searchData.length > 0) ||
              (trendingData && trendingData.length > 0)) && (
              <div className="absolute top-[calc(100%_+_0.2rem)] z-[1004] h-auto w-full min-w-[300px] rounded-2xl border-[1px] bg-white py-2 text-start text-[#7A7A7A] shadow-md ">
                <ul className="text-[14px]">
                  {searchData &&
                    searchData.length > 0 &&
                    searchData.map((item, index) => (
                      <li
                        key={index}
                        onMouseDown={() => {
                          handleSuggestionSubmit(item)
                        }}
                        className="flex items-center gap-3  p-2 px-3 py-1 hover:bg-gray"
                      >
                        <div className="cursor-pointer pr-2">
                          <Icons.Search className="h-auto w-[20px] text-black/80" />
                        </div>
                        <SearchCompletion
                          text={item.toLowerCase()}
                          query={searchVal.trim().toLowerCase()}
                        />
                      </li>
                    ))}
                  {trendingData &&
                    trendingData.length > 0 &&
                    trendingData.map((item, index) => (
                      <li
                        key={index}
                        onMouseDown={() => {
                          handleSuggestionSubmit(item)
                        }}
                        className="flex items-center gap-3  p-2 px-3 py-1 hover:bg-gray"
                      >
                        <div className="cursor-pointer pr-2">
                          <Icons.trendingUp className="h-auto w-[20px] text-green" />
                        </div>
                        <Text>
                          <span className="font-semibold text-green">
                            {item.toLowerCase()}
                          </span>
                        </Text>
                      </li>
                    ))}
                </ul>
              </div>
            )}
        </div>
        {/* <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#eeeeee]">
          <Icons.Microphone className="h-6 w-6 text-black/80" />
        </div> */}
      </div>
    </div>
  )
}

export const SearchMobile = ({ fullView }: { fullView?: boolean }) => {
  const router = useRouter()
  const [searchVal, setSearchVal] = useState('')
  const sp = useSearchParams()
  const debouncedQuery = useDebounce(searchVal, 200)

  const { data: searchText } = useSearchOnTypeQuery(debouncedQuery)

  const { data: searchRecommendations } = useSearchQuery()

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(sp?.toString())
      params.set(name, value)
      return params.toString()
    },
    [sp]
  )

  const handleSearchSubmit = () => {
    if (searchVal.length == 0) {
      return
    } else {
      // insertSearchTextMutation.mutate(searchVal)
      setIsMobileSearchVisible(false)
      router.push('/search/?' + createQueryString('q', searchVal))
    }
  }

  const handleSuggestionSubmit = (suggestion: string) => {
    // insertSearchTextMutation.mutate(suggestion)
    setSearchVal(suggestion)
    setIsMobileSearchVisible(false)
    router.push('/search/?' + createQueryString('q', suggestion))
  }

  const [searchData, setSearchData] = useState<string[]>([])
  const [trendingData, setTrendingData] = useState<string[]>([])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value)
  }

  useEffect(() => {
    if (searchVal) {
      setSearchData(searchText?.map((st) => st.description) || [])
      setTrendingData([])
    } else {
      setSearchData(
        searchRecommendations
          ?.filter((fil) => fil.sreachType === 'history')
          ?.map((st) => st.description) || []
      )
      setTrendingData(
        searchRecommendations
          ?.filter((fil) => fil.sreachType === 'popular')
          ?.map((st) => st.description) || []
      )
    }
  }, [searchVal, searchText, searchRecommendations])

  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false)

  const handleMoveLeft = () => {
    setIsMobileSearchVisible(false)
  }

  return (
    <div
      className="relative px-4 sm:px-0"
      style={{ width: fullView ? '100%' : 'auto' }}
    >
      {fullView ? (
        <div className="searchContMob flex w-full items-center overflow-hidden rounded-full border-2 border-white bg-[#78928d] px-4 opacity-75 focus-within:border-[#2977f5]">
          <Input
            type="text"
            name="search"
            className="focus-visible:out z-0 h-10 flex-1 border-0 bg-[#78928d] pl-0 pr-2 placeholder:text-[14px] placeholder:font-normal placeholder:text-white focus:border-0 focus:outline-none focus-visible:shadow-none focus-visible:outline-none focus-visible:ring-0"
            placeholder="Search"
            readOnly={true}
            onClick={() => setIsMobileSearchVisible(true)}
          />
          {searchVal.length > 0 ? (
            <Icon
              as={RxCross1}
              color="lyk-red"
              className="h-5 w-5 cursor-pointer lg:h-5 lg:w-5"
              onClick={() => setSearchVal('')}
            />
          ) : (
            <Icons.Search className="h-5 w-5 cursor-pointer text-white lg:h-6 lg:w-6" />
          )}
        </div>
      ) : (
        <div>
          <Icons.Search
            className="z-20 h-7 w-7 cursor-pointer text-black/80"
            onClick={() => setIsMobileSearchVisible(true)}
          />
        </div>
      )}
      <Modal
        isOpen={isMobileSearchVisible}
        onClose={() => setIsMobileSearchVisible(false)}
        size={'full'}
      >
        <ModalOverlay />
        <ModalContent>
          <div className="fixed left-0 top-0 z-20 h-screen w-screen overflow-y-auto bg-white">
            <span className="mx-auto flex w-[95%] items-center justify-center">
              <button className="z-20 mt-0 h-[50px] w-[50px] border-0 bg-white text-[#94989b]">
                <Icons.moveLeft className="w-[44px]" onClick={handleMoveLeft} />
              </button>
              <div className="mx-auto my-[20px] flex w-[80%] items-center overflow-hidden rounded-[30px] border-[1px] border-[#d3d3d3] px-[20px] shadow-md focus-within:border-[1px] focus-within:border-[#2977f5]">
                <input
                  className="shadow-0 relative z-10 mb-0 h-[49px] w-full focus-visible:shadow-none focus-visible:outline-none focus-visible:ring-0"
                  placeholder="Search..."
                  name="search"
                  type="search"
                  defaultValue={sp?.get('q') || ''}
                  value={searchVal}
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    if (event.key == 'Enter') {
                      handleSearchSubmit()
                    }
                  }}
                  autoFocus={true}
                  inputMode="search"
                  enterKeyHint="search"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="false"
                  aria-haspopup="false"
                  aria-autocomplete="list"
                />
                <input type="submit" hidden />
                {searchVal.length > 0 ? (
                  <Icon
                    as={RxCross1}
                    color="lyk-red"
                    h="auto"
                    w="20px"
                    onClick={() => setSearchVal('')}
                  />
                ) : (
                  <Icons.Search className="h-auto w-[20px] text-black/80" />
                )}
              </div>
              {/* <Icons.Microphone className="mr-4 w-[44px] cursor-pointer rounded-full bg-white p-2 opacity-75" /> */}
            </span>

            <div className="mx-auto my-[10px] w-[95%] text-[16px]">
              <ul className="">
                {searchData.map((item, index) => (
                  <li
                    key={index}
                    onMouseDown={() => {
                      handleSuggestionSubmit(item)
                    }}
                    className="px-auto flex items-center gap-3 py-[6px]"
                  >
                    <div className="pr-2">
                      <Icons.Search className="h-auto w-[20px] text-black/80" />
                    </div>
                    <SearchCompletion
                      text={item.toLowerCase()}
                      query={searchVal.trim().toLowerCase()}
                    />
                  </li>
                ))}
                {trendingData.length > 0 &&
                  trendingData.map((item, index) => (
                    <li
                      key={index}
                      onMouseDown={() => {
                        handleSuggestionSubmit(item)
                      }}
                      className="px-auto flex items-center gap-3 py-[6px]"
                    >
                      <div className="pr-2">
                        <Icons.trendingUp className="h-auto w-[20px] text-green" />
                      </div>
                      <Text>
                        <span className="font-semibold text-green">
                          {item.toLowerCase()}
                        </span>
                      </Text>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </div>
  )
}

export const SearchCompletion = ({
  text,
  query,
}: {
  text: string
  query: string
}) => {
  const index = text.indexOf(query)
  const before = index < 0 ? text : text.slice(0, index)
  const after = text.slice(index + query.length)

  return (
    <Text>
      <span className="font-semibold text-black">{before}</span>
      <span className="font-medium text-[#7A7A7A]">
        {index >= 0 ? query : ''}
      </span>
      <span className="font-semibold text-black">
        {index >= 0 ? after : ''}
      </span>
    </Text>
  )
}

export const SearchHeader: React.FC = () => {
  const router = useRouter()
  const pathname = usePathname()
  const { user } = useUser()
  const { toggleSidebar } = useSidebar()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: notificationCount } = useNotificationCount()
  const { languageIds, interestIds } = useOnboarding()

  const languageIdsList = user
    ? user?.languageIds?.split(',')
    : languageIds
      ? languageIds.split(',')
      : []
  const interestIdsList = user
    ? user?.interestIds?.split(',')
    : interestIds
      ? interestIds.split(',')
      : []

  const [scrollY, setScrollY] = useState(0)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        setScrollY(window?.scrollY)
      }
      handleScroll()
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [])

  return (
    <>
      <VideoUploadFlow onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
      {/* <VideoPublished isOpen={isOpen} onClose={onClose} /> */}

      <Flex className="flex-col">
        <Box
          w="100%"
          position={'fixed'}
          left={0}
          right={0}
          top={0}
          zIndex={{
            base: 999,
            '2xl': excludedPages.includes(pathname || '') ? 999 : 1001,
          }}
          display={
            pathname === '/home' &&
            (!languageIdsList?.length || !interestIdsList?.length)
              ? 'block'
              : 'none'
          }
        >
          <SelectLangInterest />
        </Box>

        <Box
          w="100%"
          position={'fixed'}
          left={0}
          right={0}
          top={
            pathname === '/home' &&
            (!languageIdsList?.length || !interestIdsList?.length)
              ? 24
              : 0
          }
          px={[4, 4, 4]}
          py={2}
          display="flex"
          alignItems="center"
          h={NAVBAR_HEIGHT_PX}
          zIndex={{
            base: 999,
            '2xl': excludedPages.includes(pathname || '') ? 999 : 1001,
          }}
          transition="0.2s linear"
          borderColor="gray.100"
          className={
            scrollY > 2 && pathname === '/home'
              ? 'bgBlurNav'
              : pathname !== '/home'
                ? 'bg-white'
                : ''
          }
        >
          <Box position="relative" w="100%">
            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Flex alignItems="center" gap={{ base: 2, sm: 4 }}>
                <Icons.HamburgerMenu
                  className="mt-[5px] flex h-6 w-6 shrink-0 cursor-pointer text-black"
                  onClick={toggleSidebar}
                />
                <Link href={user !== null ? '/home' : '/'}>
                  <Image
                    src={`/lykstage-logo.svg`}
                    alt="logo"
                    w={['140px', '140px', '160px', '170px']}
                    h="auto"
                    minW="110px"
                  />
                </Link>
              </Flex>

              <Show above="md">
                <div
                  className="mx-8 w-full max-w-[650px] flex-1"
                  style={{
                    opacity:
                      pathname !== '/home' ? '1' : scrollY > 260 ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out',
                  }}
                >
                  <Search />
                </div>
              </Show>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                columnGap={4}
              >
                <Show below="md">
                  <div
                    className="flex-1"
                    style={{
                      opacity:
                        pathname !== '/home' ? '1' : scrollY > 186 ? '1' : '0',
                      transition: 'opacity 0.3s ease-in-out',
                    }}
                  >
                    <SearchMobile />
                  </div>
                </Show>
                <Show above="md">
                  <Button
                    onClick={() => {
                      if (user !== null) {
                        onOpen()
                      } else {
                        router.push('/auth/login')
                      }
                    }}
                    variant={'unstyled'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icons.CamPlus className="h-9 w-9 text-black lg:h-9 lg:w-9" />
                  </Button>
                </Show>

                {user ? <Notifications num={notificationCount} /> : <></>}

                {user !== null ? (
                  <UserProfileBtn />
                ) : (
                  <Button
                    bg="lyk-green"
                    color="white"
                    _hover={{ bg: 'lyk-green' }}
                    fontSize={12}
                    onClick={() => router.push('/auth/signup')}
                  >
                    Get Started
                  </Button>
                )}
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </>
  )
}
