'use client'

import Link from 'next/link'
import { Icons } from '../icons'
import { usePathname } from 'next/navigation'
import { useWindowDimensions } from '@/lib/use-window-dimensions'
import { useUser } from '@/src/store/user-store'
import { useSidebar } from '@/src/store/sidebar-store'
import { useEffect } from 'react'
import { NAVBAR_HEIGHT_PX, SIDEBAR_WIDTH_PX } from '@/lib/constans'
import { Box, Flex, Image, useDisclosure } from '@chakra-ui/react'
import { LanguageModal } from './language-modal'
import { InterestModal } from './interest-modal'
import { useOnboarding } from '@/src/store/onboarding-store'
import { useLanguagesQuery } from '@/src/queries/auth/languages-query'
import { useInterestQuery } from '@/src/queries/auth/interests-query'
import { cn } from '@/lib/utils'
import { PwaOnboardingModal } from '../PWA/pwa-onboarding'

export const excludedPages = [
  '/community',
  '/data-usage',
  '/privacypolicy',
  '/termsofservice',
  '/faqs',
  '/contact-us',
  '/how-earnings-work',
]

export const Sidebar = () => {
  const { width } = useWindowDimensions()
  const pathname = usePathname()
  const { user, _hasHydrated } = useUser()
  const { languageIds, interestIds } = useOnboarding()
  const allLanguages = useLanguagesQuery().data
  const allInterests = useInterestQuery().data
  const languageIdsList = user
    ? user?.languageIds?.split(',')
    : languageIds
      ? languageIds.split(',')
      : []
  const interestIdsList = user
    ? user?.interestIds?.split(',')
    : interestIds
      ? interestIds.split(',')
      : []
  const languages = allLanguages?.flatMap((language) =>
    languageIdsList?.includes(language.id) ? [language.language] : []
  )
  const interests = allInterests?.flatMap((interest) =>
    interestIdsList?.includes(interest.interestId) ? [interest.interest] : []
  )
  const { isSideBarOpen, closeSidebarIfOpen, closeSidebar, toggleSidebar } =
    useSidebar()

  useEffect(() => {
    if (width && width <= 1280) {
      closeSidebarIfOpen()
    }
  }, [width, closeSidebarIfOpen, pathname])

  useEffect(() => {
    if (excludedPages.includes(pathname || '')) {
      closeSidebar()
    }
  }, [pathname, closeSidebar])

  const {
    isOpen: isInterestOpen,
    onOpen: onInterestOpen,
    onClose: onInterestClose,
  } = useDisclosure()

  const {
    isOpen: isLanguageOpen,
    onOpen: onLanguageOpen,
    onClose: onLanguageClose,
  } = useDisclosure()
  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (
      width &&
      width <= 1280 &&
      window.location.origin + pathname === event.currentTarget.href
    ) {
      closeSidebarIfOpen()
    }
  }

  // const {
  //   isOpen: isPwaOpen,
  //   onOpen: onPwaOpen,
  //   onClose: onPwaClose,
  // } = useDisclosure()
  // function isPWA() {
  //   //@ts-ignore
  //   return (window.matchMedia('(display-mode: standalone)').matches || window.navigator?.standalone || document.referrer.includes('android-app://'));
  // }
  // useEffect(() => {
  //   if (isPWA() && width && width <= 600 && _hasHydrated && !user && localStorage.getItem('pwaOnboarded') !== 'true') { onPwaOpen() }
  // }, [width, _hasHydrated])

  const sidebarTopValue =
    pathname === '/home' &&
    (!languageIdsList?.length || !interestIdsList?.length)
      ? 'xxl:top-[95px] overflow-hidden'
      : 'top-0'

  return (
    <>
      <InterestModal isOpen={isInterestOpen} onClose={onInterestClose} />
      <LanguageModal isOpen={isLanguageOpen} onClose={onLanguageClose} />
      {/* <PwaOnboardingModal isOpen={isPwaOpen} onClose={onPwaClose} /> */}
      <Box
        className={cn(
          'invisible fixed bottom-0 left-0 top-0 z-[1000] block h-screen w-screen bg-black/50',
          excludedPages.includes(pathname || '') ? '' : 'xxl:hidden'
        )}
        onClick={closeSidebar}
        opacity={isSideBarOpen ? 1 : 0}
        visibility={isSideBarOpen ? 'visible' : 'hidden'}
        transition="all 0.3s linear"
      ></Box>

      <div
        className={`ease fixed ${sidebarTopValue} top-0 z-[1000] flex h-screen flex-col bg-white xxl:bg-transparent`}
        style={{
          transition: 'left ease 0.5s',
          width: SIDEBAR_WIDTH_PX,
          left: isSideBarOpen ? 0 : `-${SIDEBAR_WIDTH_PX}`,
        }}
      >
        <div
          className={`transition-top relative top-0 flex flex-1 flex-col px-4 duration-500 ease-in-out`}
        >
          <Flex
            mr={['15px', '20px', '20px', '80px']}
            alignItems="center"
            gap={{ base: 2, sm: 4 }}
            h={NAVBAR_HEIGHT_PX}
            className="visible xxl:invisible"
          >
            <Icons.HamburgerMenu
              className="mt-[4px] flex h-6 w-6 shrink-0 cursor-pointer text-black"
              onClick={toggleSidebar}
            />
            <Link href="/home" onClick={handleLinkClick}>
              <Image
                src={`/lykstage-logo.svg`}
                alt="logo"
                w={['140px', '140px', '160px', '170px']}
                h="auto"
                minW="110px"
              />
            </Link>
          </Flex>
          <div className="flex-1 overflow-y-scroll overscroll-contain pb-4 pt-2 scrollbar-none">
            <ul className="text-[16px]">
              <Link href="/home" onClick={handleLinkClick}>
                <li
                  className={`flex w-full cursor-pointer items-center gap-2 whitespace-nowrap rounded-lg ${pathname?.startsWith('/home') ? 'bg-[#24BFA320] text-green' : ''} px-5 py-3 hover:bg-[#24BFA320]`}
                >
                  <span className="icons">
                    <Icons.Home className="h-[20px] w-[20px]" />
                  </span>
                  <span className="text">Home</span>
                </li>
              </Link>
              <Link href="/earnings" onClick={handleLinkClick}>
                <li
                  className={`my-1 flex cursor-pointer items-center gap-2 ${pathname?.startsWith('/earnings') ? 'bg-[#24BFA320] text-green' : ''} rounded-lg px-5 py-3 hover:bg-[#24BFA320]`}
                >
                  <span className="icons">
                    <Icons.Wallet className="h-[20px] w-[20px]" />
                  </span>
                  <span className="text">Earnings</span>
                </li>
              </Link>
              <Link href="/subscriptions" onClick={handleLinkClick}>
                <li
                  className={`my-1 flex cursor-pointer items-center gap-2 ${pathname?.startsWith('/subscriptions') ? 'bg-[#24BFA320] text-green' : ''} rounded-lg px-5 py-3 hover:bg-[#24BFA320]`}
                >
                  <span className="icons">
                    <Icons.UserCheck className="h-[20px] w-[20px]" />
                  </span>
                  <span className="text">Subscription</span>
                </li>
              </Link>
              <Link href="/watchlist" onClick={handleLinkClick}>
                <li
                  className={`my-1 flex cursor-pointer items-center gap-2 ${pathname?.startsWith('/watchlist') ? 'bg-[#24BFA320] text-green' : ''} rounded-lg px-5 py-3 hover:bg-[#24BFA320]`}
                >
                  <span className="icons">
                    <Icons.Watchlist className="h-[20px] w-[20px]" />
                  </span>
                  <span className="text">Watchlist</span>
                </li>
              </Link>
              {/* <Link href="#">
                <li className="my-1 flex cursor-pointer items-center gap-2 rounded-lg px-5 py-3 hover:bg-[#24BFA320]">
                  <span className="icons">
                    <Icons.Rentals className="h-[20px] w-[20px]" />
                  </span>
                  <span className="text">Rentals &amp; Premium</span>
                </li>
              </Link> */}
              {user !== null && (
                <Link
                  href={'/c/' + user.channelShareName}
                  onClick={handleLinkClick}
                >
                  <li className="my-1 flex cursor-pointer items-center gap-2 rounded-lg px-5 py-3  hover:bg-[#24BFA320]">
                    <span className="icons">
                      <Icons.play className="h-[20px] w-[20px] text-black" />
                    </span>
                    <span className="text">My Channel</span>
                  </li>
                </Link>
              )}

              <Link href="/creator-analytics" onClick={handleLinkClick}>
                <li
                  className={`my-1 flex cursor-pointer items-center gap-2 ${pathname?.startsWith('/creator-analytics') ? 'bg-[#24BFA320] text-green' : ''} rounded-lg px-5 py-3 hover:bg-[#24BFA320]`}
                >
                  <span className="icons">
                    <Icons.Creator className="h-[20px] w-[20px]" />
                  </span>
                  <span className="text">Creator dashboard</span>
                </li>
              </Link>
            </ul>
            {user !== null ? (
              <></>
            ) : (
              <>
                <hr className="my-4 border-[#E0DFDF]" />
                <p className="pb-3 text-[16px]">
                  Sign in to like videos,
                  <br /> subscribe, and earn!
                </p>
                <Link href="/auth/login" onClick={handleLinkClick}>
                  <button className="w-full rounded-lg bg-[#24BFA3] py-2 text-[16px] text-white duration-300">
                    Sign in
                  </button>
                </Link>
              </>
            )}
            <hr className="my-4 border-[#E0DFDF]" />
            <div className="text-[16px]">
              <p
                className="flex cursor-pointer items-center gap-3"
                onClick={onLanguageOpen}
              >
                Languages
                <Icons.Write className="h-[20px] w-[20px] text-black" />
              </p>
              <p className="pt-2 text-[14px] text-black/80">
                {languages?.length === 0 && 'No language preferences'}
                {languages?.slice(0, 3).join(', ')} <br />{' '}
                {languages?.length && languages?.length > 3
                  ? '+' + (languages?.length - 3) + ' More'
                  : ''}
              </p>
            </div>
            <hr className="my-4 border-[#E0DFDF]" />
            <div className="text-[16px]">
              <p
                className="flex cursor-pointer items-center gap-3"
                onClick={onInterestOpen}
              >
                Interests
                <Icons.Write className="h-[20px] w-[20px] text-black" />
              </p>
              <p className="pt-2 text-[14px] text-black/80">
                {interests?.length === 0 && 'No interest preferences'}
                {interests?.slice(0, 3).join(', ')} <br />{' '}
                {interests?.length && interests?.length > 3
                  ? '+' + (interests?.length - 3) + ' More'
                  : ''}
              </p>
            </div>
            <hr className="my-4 border-[#E0DFDF]" />
            <Link
              onClick={handleLinkClick}
              href={'/how-earnings-work'}
              className="hover:opacity-0.6 text-green hover:underline"
            >
              How earnings work &rarr;
            </Link>
            <p className="mt-2">
              Got more questions?{' '}
              <Link
                onClick={handleLinkClick}
                href={'/faqs'}
                className="hover:opacity-0.6 text-green hover:underline"
              >
                View FAQs
              </Link>
            </p>
            <p className="mt-5 pb-20 text-[15px]">© 2024 LYKSTAGE</p>
          </div>
        </div>
      </div>
    </>
  )
}
