import { useReadNotificationMutation } from '@/src/mutations/home/notifications'
import { useNotifications } from '@/src/queries/home/notifications'
import { useUser } from '@/src/store/user-store'
import { Box, Flex, Image, Text, Link, Skeleton } from '@chakra-ui/react'
import { Bell } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import { Popover, PopoverContent, PopoverTrigger } from './popover'
const LoadingNotifCard = () => {
  return (
    <>
      <Flex className="w-full" gap="2" p="2">
        <Skeleton
          rounded="md"
          w={'30%'}
          maxW={'80px'}
          aspectRatio={1 / 1}
          overflow="hidden"
          bg="gray.300"
        ></Skeleton>
        <Box className="flex flex-1 flex-col items-start">
          <Skeleton height="15px" width="80%" mb="2" />
          <Skeleton height="8px" width="40%" />
        </Box>
      </Flex>
    </>
  )
}
const Notif = ({
  variant,
  notification,
  when,
  thumbnail,
  link,
  isRead,
  id,
  isOpen,
  videoUrl,
}: {
  notification: React.ReactNode
  variant: string
  when: string
  thumbnail?: string
  link?: string
  isRead: string
  id: string
  isOpen: boolean
  videoUrl?: string
}) => {
  function formatDate(when: string) {
    const d = new Date(when)
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
  }
  const { mutate } = useReadNotificationMutation()

  useEffect(() => {
    if (isRead == '0' && isOpen) {
      mutate(id)
    }
  }, [isOpen, id, isRead, mutate])

  return (
    <Link
      _hover={{ textDecor: 'none' }}
      href={link ? (link == 'videoUrl' ? videoUrl || '#' : link) : '#'}
    >
      <Flex
        _hover={{ bg: '#eaeaea50' }}
        mb={1}
        justify={'flex-start'}
        align={'center'}
        gap={3}
        p={3}
        mr={2}
        rounded={'md'}
      >
        <Flex
          rounded={'md'}
          flex={1.25}
          aspectRatio={1}
          bg={'#D3F2ED'}
          w={'100%'}
          justify={'center'}
          align={'center'}
          bgRepeat={'no-repeat'}
          bgSize={'cover'}
          bgPosition={'center'}
          overflow="hidden"
        >
          <Image
            src={(() => {
              if (variant == 'earning') {
                return '/logo-color-circle.svg'
              } else if (variant == 'premium') {
                return '/notifications/premium.svg'
              } else if (variant == 'bonus') {
                return '/notifications/bonus.svg'
              } else {
                return thumbnail
              }
            })()}
            width={20}
            height={20}
            boxSize={(() => {
              if (variant == 'bonus') {
                return '50%'
              } else {
                return '55%'
              }
            })()}
            fallback={<Bell size={30} color="gray" />}
            alt="Notification"
            objectFit={'cover'}
            objectPosition={'center'}
          />
        </Flex>
        <Box flex={5}>
          <Text
            noOfLines={2}
            fontSize={['sm', 'sm', 'md']}
            fontWeight={500}
            color={'#3e3e3e'}
          >
            {notification}
          </Text>
          <Text noOfLines={1} color={'#747474'} fontSize={13} fontWeight={400}>
            {formatDate(when)}
          </Text>
        </Box>
      </Flex>
    </Link>
  )
}

const NotificationContent = ({ isOpen }: { isOpen: boolean }) => {
  const result = useNotifications()
  const {
    status,
    data,
    isFetchingNextPage,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = result
  const hasNextPageRef = React.useRef<boolean>(hasNextPage)
  const { ref, inView } = useInView()
  const { user } = useUser()
  useEffect(() => {
    hasNextPageRef.current = hasNextPage
  }, [hasNextPage])
  const carouselRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (hasNextPageRef.current && !isFetchingNextPage && !isFetching && inView)
      fetchNextPage()
  }, [inView, isFetchingNextPage, fetchNextPage, isFetching])

  let NotifContent: React.ReactNode = null
  if (status == 'pending') {
    NotifContent = (
      <>
        {Array(5)
          .fill('-')
          .map((_, i) => (
            <LoadingNotifCard key={i} />
          ))}
      </>
    )
  }
  if (data?.pages.flatMap((page) => page.notifications).length === 0) {
    return (
      <Flex
        w="100%"
        flex="1"
        flexDir="column"
        align="center"
        justify="center"
        p="10"
      >
        <Image
          src="/failed.svg"
          width={[120, 120, 150]}
          height={[120, 120, 150]}
          alt="Failed Icon"
        />
        <Text
          mt={4}
          color="black"
          fontWeight={600}
          fontSize={['sm', 'sm', 'md']}
        >
          No Notifications found!
        </Text>
      </Flex>
    )
  }
  const notificationsTypeMapping = {
    updateprofile: {
      link: '/account',
      variant: 'video',
    },
    welcome: {
      link: '/home',
      variant: 'premium',
    },
    likeVideo: {
      link: 'videoUrl',
      variant: 'video',
    },
    commentVideo: {
      link: 'videoUrl',
      variant: 'video',
    },
    likeComment: {
      link: 'videoUrl',
      variant: 'video',
    },
    reportVideo: {
      link: 'videoUrl',
      variant: 'video',
    },
    deleteVideo: {
      link: '/c/' + user?.channelShareName,
      variant: 'video',
    },
    changepass: {
      link: '/account',
      variant: 'video',
    },
    updateVideoInfo: {
      link: 'videoUrl',
      variant: 'video',
    },
    requesrpass: {
      link: '/earnings',
      variant: 'earning',
    },
    rewardInfo: {
      link: '/earnings',
      variant: 'earning',
    },
    rewardCoin: {
      link: '/earnings',
      variant: 'earning',
    },
    videotranscodedfailed:{
      link: '/c/' + user?.channelShareName+'?tab=drafts',
      variant: 'video',
    },
    videotranscodedsuccess:{
      link: '/c/' + user?.channelShareName,
      variant: 'video',
    },
  } as {
    [key: string]: {
      link: string
      variant: string
    }
  }
  if (status == 'success') {
    NotifContent = data?.pages.map((page, i) => {
      return (
        <React.Fragment key={i}>
          {page.notifications.map((notif) => {
            return (
              <>
                <Notif
                  key={notif._id}
                  thumbnail={notif.imageUrl}
                  when={notif.createdAt}
                  notification={
                    <>
                      {notif.message}
                      {notif.urlText && (
                        <a
                          href={notif.url}
                          style={{
                            color: '#24BFA3',
                            textDecoration: 'underline',
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          {notif.urlText}
                        </a>
                      )}
                    </>
                  }
                  variant={
                    notificationsTypeMapping?.[String(notif.notificationType).replaceAll('-','')]
                      ?.variant || 'video'
                  }
                  link={
                    notificationsTypeMapping?.[String(notif.notificationType).replaceAll('-','')]?.link ||
                    ''
                  }
                  isRead={notif.isread}
                  id={notif._id}
                  isOpen={isOpen}
                  videoUrl={notif.encryptVideoUrl}
                />
              </>
            )
          })}
        </React.Fragment>
      )
    })
  }
  if (status == 'error') {
    return (
      <Flex
        w="100%"
        flex="1"
        flexDir="column"
        align="center"
        justify="center"
        p="10"
      >
        <Image src="/failed.svg" width={150} height={150} alt="Failed Icon" />
        <Text mt={4} color="black" fontWeight={600} fontSize={18}>
          Failed to load notifications!
        </Text>
      </Flex>
    )
  }
  return (
    <>
      <Box
        overflowY={'auto'}
        maxH={300}
        ref={carouselRef}
        overscrollBehavior="contain"
        className="scrollbar-thin"
      >
        {NotifContent}
        {isFetching &&
          Array(2)
            .fill('-')
            .map((_, i) => <LoadingNotifCard key={i} />)}
        <div ref={ref} className="pb-5"></div>
      </Box>
    </>
  )
}
export const Notifications = ({ num }: { num: string }) => {
  const [open, setOpen] = useState(false)

  return (
    <Popover placement="bottom" open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        onClick={() => setOpen((v) => !v)}
        className="relative flex items-center justify-center"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          rounded={'xl'}
          position={'absolute'}
          bg={'lyk-red'}
          w={5}
          h={5}
          top={-1.5}
          left={-1}
          display={num == undefined || num == '0' ? 'none' : ''}
        >
          <Text fontSize={12} color={'white'}>
            {num}
          </Text>
        </Flex>
        <Image boxSize={7} src="/notifications.svg" alt="notifications" />
      </PopoverTrigger>
      <PopoverContent
        // w={['90vw', '90vw', 400]}
        // boxShadow="2xl"
        // border={'none'}
        // rounded={'xl'}
        // overscrollBehavior="contain"
        // _focusVisible={{
        //   boxShadow: 'none',
        //   border: 'none',
        // }}
        className="z-[1010] mt-2 w-[90vw] rounded-xl bg-white p-2 shadow-[0_3.49px_24.44px_0_rgba(0,0,0,0.25)] sm:w-[400px]"
      >
        <Text mb={3} fontSize={[16]} fontWeight={500} color={'#3e3e3e'} ml={2}>
          Your notifications
        </Text>
        <NotificationContent isOpen={open} />
      </PopoverContent>
    </Popover>
  )
}
