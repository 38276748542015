import { create } from 'zustand'

export interface SidebarSlice {
  isSideBarOpen: boolean
  toggleSidebar: () => void
  closeSidebar: () => void
  openSidebar: () => void
  closeSidebarIfOpen: () => void
}

export const useSidebar = create<SidebarSlice>((set, get) => ({
  isSideBarOpen: true,
  toggleSidebar: () =>
    set((state) => ({ isSideBarOpen: !state.isSideBarOpen })),
  closeSidebar: () => set(() => ({ isSideBarOpen: false })),
  openSidebar: () => set(() => ({ isSideBarOpen: true })),
  closeSidebarIfOpen: () => {
    if (get().isSideBarOpen) {
      set(() => ({ isSideBarOpen: false }))
    }
  },
}))
